/**
 * 触发 window.resize
 */
 export function triggerWindowResizeEvent() {
    const event = document.createEvent('HTMLEvents')
    event.initEvent('resize', true, true)
    event.eventType = 'message'
    window.dispatchEvent(event)
  }
  
  export function handleScrollHeader(callback) {
    let timer = 0
  
    let beforeScrollTop = window.pageYOffset
    callback = callback || function () { }
    window.addEventListener(
      'scroll',
      () => {
        clearTimeout(timer)
        timer = setTimeout(() => {
          let direction = 'up'
          const afterScrollTop = window.pageYOffset
          const delta = afterScrollTop - beforeScrollTop
          if (delta === 0) {
            return false
          }
          direction = delta > 0 ? 'down' : 'up'
          callback(direction)
          beforeScrollTop = afterScrollTop
        }, 50)
      },
      false
    )
  }
  
  export function isIE() {
    const bw = window.navigator.userAgent
    const compare = (s) => bw.indexOf(s) >= 0
    const ie11 = (() => 'ActiveXObject' in window)()
    return compare('MSIE') || ie11
  }
  
  export function detectDevice(){
    let equipmentType = "";
    let agent = navigator.userAgent.toLowerCase();
    let android = agent.indexOf("android");
    let iphone = agent.indexOf("iphone");
    let ipad = agent.indexOf("ipad");
    if(android != -1){
        equipmentType = "android";
    }
    if(iphone != -1 || ipad != -1){
        equipmentType = "ios";
    }
    return equipmentType;
  }
  
  export function scorePassword(pass) {
    let score = 0
    if (!pass) {
      return score
    }
    // award every unique letter until 5 repetitions
    const letters = {}
    for (let i = 0; i < pass.length; i++) {
      letters[pass[i]] = (letters[pass[i]] || 0) + 1
      score += 5.0 / letters[pass[i]]
    }
  
    // bonus points for mixing it up
    const variations = {
      digits: /\d/.test(pass),
      lower: /[a-z]/.test(pass),
      upper: /[A-Z]/.test(pass),
      nonWords: /\W/.test(pass)
    }
  
    let variationCount = 0
    for (var check in variations) {
      variationCount += (variations[check] === true) ? 1 : 0
    }
    score += (variationCount - 1) * 10
  
    return parseInt(score)
  }
  
  export const Format = function (date, fmt) {
    
    var o = {
      "M+": date.getMonth() + 1,                 //月份
      "d+": date.getDate(),                    //日
      "h+": date.getHours(),                   //小时
      "m+": date.getMinutes(),                 //分
      "s+": date.getSeconds(),                 //秒
      "q+": Math.floor((date.getMonth() + 3) / 3), //季度
      "S": date.getMilliseconds()             //毫秒
    };
    if (/(y+)/.test(fmt))
      fmt = fmt.replace(RegExp.$1, (date.getFullYear() + "").substr(4 - RegExp.$1.length));
    for (var k in o)
      if (new RegExp("(" + k + ")").test(fmt))
        fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
    return fmt;
  }
  
  export const startFormat = function (date) {
    if(typeof date == 'string'){
      date = new Date(date);
    }
    return Format(date, 'yyyy-MM-dd')+'T00:00:00';
  }
  
  export const endFormat = function (date) {
    if(typeof date == 'string'){
      date = new Date(date);
    }
    return Format(date, 'yyyy-MM-dd')+'T23:59:59';
  }

  export const clearAllCookie = () => {
    var keys = document.cookie.match(/[^ =;]+(?==)/g)
    if (keys) {
      for (var i = keys.length; i--;) {
        document.cookie = keys[i] + '=0;path=/;expires=' + new Date(0).toUTCString() // 清除当前域名下的
        document.cookie = keys[i] + '=0;path=/;domain=' + document.domain + ';expires=' + new Date(0).toUTCString() // 清除当前域名下的
        // document.cookie = keys[i] + '=0;path=/;domain=ratingdog.cn;expires=' + new Date(0).toUTCString() // 清除一级域名下的或指定的
      }
    }
  }
  
  export const getUserIP = (onNewIP) => {
    
    let MyPeerConnection = window.RTCPeerConnection || window.mozRTCPeerConnection || window.webkitRTCPeerConnection;
    let pc = new MyPeerConnection({
        iceServers: []
      });
    let noop = () => {
      };
    let localIPs = {};
    let ipRegex = /([0-9]{1,3}(\.[0-9]{1,3}){3}|[a-f0-9]{1,4}(:[a-f0-9]{1,4}){7})/g;
    let iterateIP = (ip) => {
      if (!localIPs[ip]) onNewIP(ip);
      localIPs[ip] = true;
    };
    pc.createDataChannel('');
    pc.createOffer().then((sdp) => {
      sdp.sdp.split('\n').forEach(function (line) {
        if (line.indexOf('candidate') < 0) return;
        line.match(ipRegex).forEach(iterateIP);
      });
      pc.setLocalDescription(sdp, noop, noop);
    }).catch((reason) => {
      console.log(reason)
    });
    pc.onicecandidate = (ice) => {
      if (!ice || !ice.candidate || !ice.candidate.candidate || !ice.candidate.candidate.match(ipRegex)) return;
      ice.candidate.candidate.match(ipRegex).forEach(iterateIP);
    };
  }
  
  export const formatUrlToBase64 = img => {
    // domain pic convert to base64
    try {
      const canvas = document.createElement("canvas");
  
      const width = img.width
      const height = img.height
  
      canvas.width = width;
      canvas.height = height;
  
      const ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0, width, height);
  
      const result = canvas.toDataURL("image/png");
  
      return result
    } catch (error) {
      return ''
    }
  }

  export const getHashSearchParam = (key) => {
    const url = window.location.href
    const hash = url.substring(url.indexOf("#") + 1)
    const searchIndex = hash.indexOf("?")
    const search = searchIndex !== -1 ? hash.substring(searchIndex + 1) : ""
    const usp = new URLSearchParams(search)
    return usp.get(key)
  }
