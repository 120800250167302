import request from '@/utils/request';

export const getMatchList = data => request({
  url: '/v1/topic/matchList',
  data:{leagueId: process.env.VUE_APP_LEAGUEID, ...data},
})

// export const  = params => request({
//   url: '',
//   method: 'post',
//   headers: {
//     'Accept': 'multipart/form-data;charset-utf-8',
//     'Content-Type': 'multipart/form-data',
//   },
//   loading: true,
//   errorNoDialog: true,
//   data: params
// })

