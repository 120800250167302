export const sphList = {
    14: { EN: 'Group A', ZH: 'A组', VN: 'Bảng A' },
    15: { EN: 'Group B', ZH: 'B组', VN: 'Bảng B' },
    16: { EN: 'Group C', ZH: 'C组', VN: 'Bảng C' },
    17: { EN: 'Group D', ZH: 'D组', VN: 'Bảng D' },
    18: { EN: 'Group E', ZH: 'E组', VN: 'Bảng E' },
    19: { EN: 'Group F', ZH: 'F组', VN: 'Bảng F' },
    20: { EN: 'Group G', ZH: 'G组', VN: 'Bảng G' },
    21: { EN: 'Group H', ZH: 'H组', VN: 'Bảng H' },
    22: { EN: 'Group I', ZH: 'I组', VN: 'Bảng I' },
    23: { EN: 'Group J', ZH: 'J组', VN: 'Bảng J' },
    24: { EN: 'Group K', ZH: 'K组', VN: 'Bảng K' },
    25: { EN: 'Group L', ZH: 'L组', VN: 'Bảng L' },
    26: { EN: 'Group M', ZH: 'M组', VN: 'Bảng M' },
    27: { EN: 'Group N', ZH: 'N组', VN: 'Bảng N' },
    28: { EN: 'Group O', ZH: 'O组', VN: 'Bảng O' },
    29: { EN: 'Group P', ZH: 'P组', VN: 'Bảng P' },
    50: { EN: '5th～8th Classification', ZH: '5~8排位赛', sort: 'A', VN: 'Bảng 5~8'},
    51: { EN: '5th Place', ZH: '第5名决赛', sort: 'B', VN: 'Bảng 5' },
    52: { EN: '7th Place', ZH: '第7名决赛', sort: 'C', VN: 'Bảng 7' },
}


export const groupTypeList = {
    1: { EN: 'Playoff', ZH: '附加赛' },
    2: { EN: 'Qualification', ZH: '资格赛' },
    3: { EN: 'Promotion Playoffs', ZH: '升级附加赛' },
    4: { EN: 'Relegation Playoffs', ZH: '降级附加赛' },
    5: { EN: 'Preseason', ZH: '季前赛' },
    6: { EN: 'Playoffs', ZH: '季后赛' },
    7: { EN: 'Group Stage', ZH: '小组赛', VN: 'Vòng đấu bảng' },
    8: { EN: 'Knockout Stage', ZH: '淘汰赛', VN: 'Vòng loại trực tiếp' },
    9: { EN: '8th Finals', ZH: '1/8 决赛', VN: 'Vòng 1/8' },
    10: { EN: 'Quarter Finals', ZH: '1/4 决赛', VN: 'Tứ kết' },
    11: { EN: 'Semi Finals', ZH: '半决赛', VN: 'Bán kết' },
    12: { EN: '3rd Place Final', ZH: '季军赛', VN: 'Tranh hạng ba' },
    13: { EN: 'Final', ZH: '决赛', VN: 'Chung kết' },
    50: { EN: 'Group Stage', ZH: '小组赛', VN: 'Vòng đấu bảng' },
    51: { EN: '2nd Round', ZH: '二阶段 分组赛' },
    52: { EN: '17th -32nd Classification', ZH: '17-32名 排位赛' },
    53: { EN: '5th–8th Classification', ZH: '5-8 排位赛' },
}

export const stList = {
    0: { EN: 'Ended', ZH: '完场', VN: 'Kết Thúc' },
    1: { EN: 'Postponed', ZH: '推迟', VN: 'Hoãn' },
    2: { EN: 'Interrupted', ZH: '中断', VN: 'Gián Đoạ' },
    3: { EN: 'Cancelled', ZH: '取消', VN: 'Hủy Bỏ' },
    4: { EN: 'Not Started', ZH: '未开赛', VN: 'Chưa Thi Đấ' },
    5: { EN: 'Live', ZH: '进行中', VN: 'Trực Tiếp' },
    6: { EN: 'Delayed', ZH: '推迟', VN: 'Hoãn' },
    7: { EN: 'Abandoned', ZH: '废弃', VN: 'Từ Bỏ' },
    8: { EN: 'Suspended', ZH: '暂停', VN: 'Tạm Ngưng' },
}

export const tygList = {
    5: { EN: 'Score', ZH: '得分', VN: 'Ghi Bàn' },
    6: { EN: 'Corner', ZH: '角', VN: 'Phạt Góc' },
    7: { EN: 'Yellow Card', ZH: '黄牌', VN: 'Thẻ Vàng' },
    8: { EN: 'Red Card', ZH: '红牌', VN: 'Thẻ Đỏ' },
    9: { EN: 'booking', ZH: '得牌', VN: 'Cược Thẻ Phạt' }
}
export const match_period = {
    1001: { EN: 'Not started', ZH: '未开始', VN: 'Chưa Thi Đấu' },
    1002: { EN: '1H', ZH: '上半场', VN: 'Hiệp 1' },
    1003: { EN: 'HT', ZH: '半场', VN: 'Nửa Trận' },
    1004: { EN: '2H', ZH: '下半场', VN: 'Hiệp 2' },
    1005: { EN: 'FT', ZH: '常规时间结束', VN: 'Cả Trận' },
    1006: { EN: 'ET 1H', ZH: '加时 上', VN: 'Hiệp Phụ Hiệp 1' },
    1007: { EN: 'ET HT', ZH: '加时 中场', VN: 'Hiệp Phụ Giữa Trận' },
    1008: { EN: 'ET 2H', ZH: '加时 下', VN: 'Hiệp Phụ Hiệp 2' },
    1009: { EN: 'ET Ended', ZH: '加时 完', VN: 'Kết Thúc Hiệp Phụ' },
    1010: { EN: 'Penalties', ZH: '点球大战', VN: 'Đá Penalty' },
    1011: { EN: 'Ended', ZH: '完场', VN: 'Kết Thú' },
    1012: { EN: 'Awaiting ET', ZH: '等待加时赛', VN: 'Đợi Hiệp Phụ' },
    1013: { EN: 'Awaiting PEN', ZH: '等待点球大战', VN: 'Đợi Đá Penalty' },
    1014: { EN: 'PEN Finished', ZH: '点球大战结束', VN: 'Kết Thúc Penalty' },
    1015: { EN: 'Interrupted', ZH: '中断', VN: 'Gián Đoạn' },
    1016: { EN: 'Abandoned', ZH: '暂不提供', VN: 'Không có sẵn' },
    3001: { ZHO: "未開賽", VIE: "Chưa Thi Đấu", ZH: "未开赛", EN: "Not Started", VN: '' },
    3002: { ZHO: "上半場", VIE: "Hiệp 1", ZH: "上半场", EN: "1H", VN: '' },
    3003: { ZHO: "半場", VIE: "Nửa Trận", ZH: "半场", EN: "HT", VN: '' },
    3004: { ZHO: "下半場", VIE: "Hiệp 2", ZH: "下半场", EN: "2H", VN: '' },
    3005: { ZHO: "第一節", VIE: "Hiệp 1", ZH: "第一节", EN: "Q1", VN: '' },
    3006: { ZHO: "第一節结束", VIE: "Kết Thúc Hiệp 1", ZH: "第一节结束", EN: "Q1 Ended", VN: '' },
    3007: { ZHO: "第二節", VIE: "HIệp 2", ZH: "第二节", EN: "Q2", VN: '' },
    3008: { ZHO: "第二節结束", VIE: "Kết Thúc Hiệp 2", ZH: "第二节结束", EN: "Q2 Ended", VN: '' },
    3009: { ZHO: "第三節", VIE: "Hiệp 3", ZH: "第三节", EN: "Q3", VN: '' },
    3010: { ZHO: "第三節结束", VIE: "Kết Thúc Hiệp 3", ZH: "第三节结束", EN: "Q3 Ended", VN: '' },
    3011: { ZHO: "第四節", VIE: "Hiệp 4", ZH: "第四节", EN: "Q4", VN: '' },
    3012: { ZHO: "加時賽", VIE: "Hiệp Phụ", ZH: "加时赛", EN: "OT", VN: '' },
    3013: { ZHO: "全場結束", VIE: "Kết Thúc Trận Đấu", ZH: "全场结束", EN: "RT Ended", VN: '' },
    3014: { ZHO: "完場", VIE: "Kết Thúc", ZH: "完场", EN: "Ended", VN: '' },
    3015: { ZHO: "等待加時賽", VIE: "Đợi Hiệp Phụ", ZH: "等待加时赛", EN: "Awaiting OT", VN: '' },
    3016: { ZHO: "加時賽結束", VIE: "Kết Thúc Hiệp Phụ", ZH: "加时赛结束", EN: "OT Finished", VN: '' },
    3017: { ZHO: "推遲", VIE: "Hoãn", ZH: "推迟", EN: "Delayed", VN: '' },
    3018: { ZHO: "中斷", VIE: "Gián Đoạn", ZH: "中断", EN: "Interrupted", VN: '' },
    3019: { ZHO: "暫不提供", VIE: "Không có sẵn", ZH: "暂不提供", EN: "Abandoned", VN: '' },
}

export const sgsPeList = {
    1001: { EN: '90Min', ZH: '90分钟', VN: '90 Phút' },
    1006: { EN: 'PEN', ZH: '点球', VN: 'Đá phạt' },
    1013: { EN: 'ET', ZH: '加时', VN: 'Bù giờ' },
}

export const matchList = {
    50: { EN: '5th–8th Classification', ZH: '5~8排位赛', groupSort: 'H' },
    9: { EN: '1/8 Finals', ZH: '1/8 决赛', groupSort: 'I', VN: 'Vòng 1/8' },
    10: { EN: 'Quarter Finals', ZH: '1/4 决赛', groupSort: 'J', VN: 'Tứ kết' },
    11: { EN: 'Semi Finals', ZH: '半决赛', groupSort: 'K', VN: 'Bán kết' },
    12: { EN: '3rd Place Final', ZH: '季军赛', groupSort: 'L', VN: 'Tranh hạng ba' },
    13: { EN: 'Final', ZH: '决赛', groupSort: 'M', VN: 'Chung kết' },
}

export const stageGroup = {
    50: { EN: groupTypeList[50].EN, ZH: groupTypeList[50].ZH, VN: groupTypeList[50].VN, id: 50, group: [14, 15, 16, 17, 18, 19, 20, 21], sort: 'A' },
    51: { EN: groupTypeList[51].EN, ZH: groupTypeList[51].ZH, VN: groupTypeList[51].VN, id: 51, group: [22, 23, 24, 25], sort: 'A' },
    52: { EN: groupTypeList[52].EN, ZH: groupTypeList[52].ZH, VN: groupTypeList[52].VN, id: 52, group: [26, 27, 28, 29], sort: 'A' },
    53: { EN: groupTypeList[53].EN, ZH: groupTypeList[53].ZH, VN: groupTypeList[53].VN, id: 53, group: [50, 51, 52], sort: 'k' },
}

export const defaultGroupText = {
    n8: [['A1', 'B2'], ['C1', 'D2'], ['E1', 'F2'], ['G1', 'H2'], ['B1', 'A2'], ['D1', 'C2'], ['F1', 'E2'], ['H1', 'G2']],
}

export const MATCH_PERIOD_2_PERIOD_MAP = {
    3002: 3003,
    3003: 3004,
    3004: 3004,
    3005: 3005,
    3006: 3006,
    3007: 3006,
    3008: 3007,
    3009: 3007,
    3010: 3008,
    3011: 3008,
    3012: 3009,
    3015: 3009,
    4002: 4003,
    4003: 4003,
    4004: 4004,
    4006: 4005,
    4007: 4005,
    4008: 4005,
    4009: 4005,
    4010: 4005,
    4011: 4005,
    4015: 4009,
    4016: 4009,
    4017: 4009,
    8002: 8003,
    8003: 8003,
    8004: 8004,
    8006: 8005,
    8007: 8005,
    8008: 8005,
    8009: 8005,
    8010: 8005,
    8011: 8006,
    8012: 8006,
    8013: 8006,
    18002: 18003,
    18003: 18004,
    18004: 18004,
    18005: 18005,
    18006: 18006,
    18007: 18006,
    18008: 18007,
    18009: 18007,
    18010: 18008,
    18011: 18008,
    18012: 18009,
    18015: 18009,
    51002: 51002,
    51003: 51002,
    51004: 51003,
    51005: 51003,
    51006: 51004,
    51007: 51004,
    51008: 51005,
    51009: 51005,
    51010: 51006,
    51011: 51006,
    51012: 51007,
    51013: 51007,
    51014: 51008,
    51015: 51008,
    51017: 51009,
    51018: 51009,
    178002: 178005,
    178003: 178005,
    178004: 178006,
    178005: 178006,
    178006: 178007,
    178007: 178007,
    178008: 178008,
    178009: 178008,
    178010: 178009,
    178011: 178009,
    178012: 178009,
    5002: 5002,
    5003: 5002,
    5004: 5003,
    5005: 5003,
    5006: 5004,
    5007: 5004,
    5008: 5005,
    5009: 5005,
    5010: 5006,
    5011: 5006,
    13002: 13002,
    13003: 13002,
    13004: 13003,
    13005: 13003,
    13006: 13004,
    13007: 13004,
    13008: 13005,
    13009: 13005,
    13010: 13006,
    13011: 13006,
    13012: 13007,
    13013: 13007,
    13014: 13008,
    13015: 13008,
    13017: 13009,
    13018: 13009,
    15002: 15002,
    15003: 15002,
    15004: 15003,
    15005: 15003,
    15006: 15004,
    15007: 15004,
    15008: 15005,
    15009: 15005,
    15010: 15006,
    15011: 15006,
    15012: 15007,
    15013: 15007,
    15014: 15008,
    15015: 15008,
    47002: 47002,
    47003: 47002,
    47004: 47003,
    47005: 47003,
    47006: 47004,
    47007: 47004,
    47008: 47005,
    47009: 47005,
    47010: 47006,
    47011: 47006,
    2002: 2003,
    2003: 2003,
    2004: 2004,
    2005: 2004,
    2006: 2005,
    2007: 2005,
    2008: 2006,
    2009: 2006,
    2010: 2006,
    2011: 2007,
    2012: 2007,
    2013: 2007,
    6002: 6005,
    6003: 6005,
    6004: 6006,
    6005: 6006,
    6006: 6007,
    6007: 6007,
    6008: 6008,
    6009: 6008,
    6010: 6009,
    6011: 6009,
    6012: 6009,
    7003: 7004,
    7004: 7004,
    7005: 7004,
    7006: 7004,
    7008: 7005,
    7009: 7005,
    7010: 7005,
    7011: 7005,
    7013: 7006,
    7014: 7006,
    7015: 7006,
    7016: 7006,
    7018: 7007,
    7019: 7007,
    7020: 7007,
    7021: 7007,
    7023: 7008,
    7024: 7008,
    7025: 7008,
    7026: 7008,
    7028: 7009,
    7029: 7009,
    7030: 7009,
    7031: 7009,
    7033: 7010,
    7034: 7010,
    7035: 7010,
    7036: 7010,
    7038: 7011,
    7039: 7011,
    7040: 7011,
    7041: 7011,
    7043: 7012,
    7044: 7012,
    7045: 7012,
    7046: 7012,
    7048: 7013,
    7049: 7013,
    7050: 7013,
}

export const matchPeriod2period = matchPeriod => {
    return MATCH_PERIOD_2_PERIOD_MAP[matchPeriod]
}
