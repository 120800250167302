<template>
  <div class="mainContent hid-scrollbar">
    <div class="contentWrap hid-scrollbar">
      <div
        v-for="(group, groupKey) in groupData"
        :key="groupKey + 'head'"
        class="groupContent"
      >
        <div class="head">
          <div class="groupWrap">
            <div class="group">
              {{
                group[0].thirdGroup
                  ? $t("home.thirdGroup")
                  : groupText(group[0].sph)
              }}
            </div>
          </div>
          <div>{{ $t("home.Sai") }}</div>
          <div>{{ $t("home.Win/draw/lose") }}</div>
          <div>{{ $t("home.in/out") }}</div>
          <div>{{ $t("home.integration") }}</div>
        </div>
        <div class="content" :key="groupKey + 'content'">
          <div
            :class="['row', { thirdGroup: group[0].thirdGroup }]"
            v-for="(item, key) in group"
            :key="key"
          >
            <div>
              <span class="index">{{ key + 1 }}</span>
              <img :src="item.tlg" alt />
              <span>{{ item.tnm }}</span>
            </div>
            <div>{{ item.w + item.d + item.l }}</div>
            <div>{{ `${item.w}/${item.d}/${item.l}` }}</div>
            <div>{{ `${item.gs}/${item.ga}` }}</div>
            <div>{{ item.p }}</div>
            <div
              v-if="key == 0 || (key == 2 && !group[0].thirdGroup)"
              class="areaTitle"
            >
              {{
                key == 0 ? $t("home.Promotion zone") : $t("home.undetermined")
              }}
            </div>
          </div>
          <!-- <div class="promotion">{{ $t("home.Promotion zone") }}</div> -->
        </div>
      </div>
    </div>

    <div class="explain">
      <div class="stage">
        <div class="block"></div>
        <div>{{ $t("home.Promotion zone") }}</div>
      </div>
      <div class="stage undetermined">
        <div class="block"></div>
        <div>{{ $t("home.undetermined") }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { sphList, groupTypeList } from "@/utils/filedList";

export default {
  name: "Group",
  props: ["groupData"],
  methods: {
    groupText(sph) {
      return sphList[sph][this.getLocal()];
    },
    stageText() {
      return groupTypeList[8][this.getLocal()];
    },
  },
};
</script>

<style lang="scss" scoped>
.mainContent {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding: 0;
  color: $text-color;
  overflow: auto;
  border: none;

  .contentWrap {
    overflow: auto;
    flex: 1;
    // border: 1px solid $border-color;
    border-radius: 10px;
  }
  .groupContent {
    backdrop-filter: blur(10px);
    margin-bottom: 10px;
    border-radius: 10px;
       border: 1px solid $border-color;
    overflow: hidden;
  }
  .explain {
    display: flex;
    margin-top: 10px;
    .stage {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 4px 16px;
      font-weight: 500;
      font-size: 13px;
      color: $text-color;
      border-radius: 100px;
      border: 1px solid $border-color;
      // background: rgba(0, 0, 0, 0.2);
      .block {
        width: 18px;
        height: 18px;
        border-radius: 2px;
        // border: 1px solid rgba(143, 202, 53, 0.5);
        background: #CCD7F9;
        margin-right: 6px;
      }
    }
    .undetermined {
      margin-left: 10px;
      .block {
        background: #F2F5FD;
      }
    }
  }
  .head {
    display: flex;
    align-items: center;
    height: 40px;
    border-bottom: 1px solid $border-color;
    background: rgba(251, 251, 251, 0.1);
    & > div {
      flex: 1;
      font-size: 13px;
      text-align: center;
      color: $text-color;
      &:nth-child(2) {
        width: 51px;
        flex: none;
      }
      &:first-child {
        text-align: left;
      }
      &:nth-child(5) {
        box-sizing: border-box;
        padding-right: 100px;
      }
    }

    .group {
      text-align: center;
      left: 16px;
      top: 11px;
      font-size: 12px;
      color: #fff;
      margin-left: 16px;
      background: #1d1240;
      font-weight: 500;
      border-radius: 5px;
      padding: 0px 5px;
      height: 18px;
      flex: none;
      display: inline-block;
      white-space: nowrap;
    }
  }
  .content {
    overflow: auto;
    position: relative;
    .promotion {
      position: absolute;
      top: 0;
      right: 0;
      padding: 0px 5px;
      height: 17px;
      line-height: 17px;
      text-align: center;
      font-size: 12px;

      color: #ffffff;

      background: #ca0a44;
      border-radius: 0px 0px 5px 5px;
    }
    .row {
      display: flex;
      align-items: center;
      height: 34px;
      border-bottom: 0.5px solid $border-color;
      position: relative;
      .areaTitle {
        padding: 0 4px;
        border-radius: 0 0 0 9px;
        height: 14.222px;
        font-size: 10px;
        color: #fff;
        background: #0238E0;
        text-align: center;
        position: absolute;
        right: 0;
        top: 0;
        font-family: inherit !important;
      }
      &:nth-child(1),
      &:nth-child(2) {
        background: #CCD7F9;
        color: #131a3e;
      }
      &:nth-child(3) {
        background: #F2F5FD;
        color: #131a3e;
      }

      & > div {
        flex: 1;
        font-size: 13px;
        text-align: center;
        font-weight: 700;

        &:not(:first-child) {
          font-family: "DIN Alternate";
          font-style: normal;
        }
        &:nth-child(2) {
          width: 51px;
          flex: none;
        }
        &:nth-child(5) {
          box-sizing: border-box;
          padding-right: 100px;
        }
        &:first-child {
          text-align: left;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;

          .index {
            font-family: "DIN Alternate";
            font-weight: 700;
            font-size: 12px;
            margin-left: 16px;
            display: inline-block;
            width: 18px;
            height: 18px;
            vertical-align: middle;
            text-align: center;
            line-height: 18px;
          }
        }
        img {
          margin: 0 8px;
          width: 18px;
          height: 18px;
        }
      }
      &:last-child {
        border-bottom: 0;
      }
    }
    .thirdGroup {
      &:nth-child(1),
      &:nth-child(2),
      &:nth-child(3),
      &:nth-child(4) {
        background: #CCD7F9;
        color: #131a3e;
      }
    }
  }
}
.dark{
  .mainContent .groupContent{
    background: #151618;
  }
  .mainContent .head{
    background: #000;
  }
  .mainContent .content .row:nth-child(1), .mainContent .content .row:nth-child(2){
    background: #010C2E;
    color: #D2D4D9;
  }
  .mainContent .content .row:nth-child(3){
    background: #262B3B;
    color: #D2D4D9;

  }
  .mainContent .content .thirdGroup {
      &:nth-child(1),
      &:nth-child(2),
      &:nth-child(3),
      &:nth-child(4) {
        background: #010C2E;
    color: #D2D4D9;
      }
    }
  .mainContent .explain .stage .block{
    background: #010C2E;
  }
  .mainContent .explain .undetermined .block{
    background: #262B3B;
  }
}
</style>
