<template>
  <div :class="className + ' outItem'">
    <div class="bottomLine" v-if="bottom">
      <div></div>
      <div></div>
      <div :class="{ low: leftLow }"></div>
      <div :class="{ low: rightLow }"></div>
    </div>
    <div class="content unknow">
      <div :class="['item', { low: leftLow }]">
        <img
          v-if="data.tms[0] && data.tms[0].lurl"
          class="hasTms"
          :src="data.tms[0].lurl"
          alt=""
        />
        <img
          v-else
          :src="require('@/assets/imgs/comm/outDefault.svg')"
          alt=""
        />

        <div class="name">{{ data.tms[0] ? data.tms[0].na : "?" }}</div>
      </div>
      <div class="center">
        <div :class="['scoreRow', className + 'Score']" v-html="centerFn"></div>
        <!-- <div class="etRow" v-if="ET && getOvertime.length">
          <span v-for="(item, key) in getOvertime" :key="key">
            {{ sgsPeList[item.pe][getLocal()] + item.sc[0] + "-" + item.sc[1] }}
          </span>
        </div> -->
      </div>
      <div :class="['item', { low: rightLow }]">
        <img
          v-if="data.tms[1] && data.tms[1].lurl"
          class="hasTms"
          :src="data.tms[1].lurl"
          alt=""
        />
        <img
          v-else
          :src="require('@/assets/imgs/comm/outDefault.svg')"
          alt=""
        />
        <div class="name">{{ data.tms[1] ? data.tms[1].na : "?" }}</div>
      </div>
    </div>
    <div class="line" v-if="!bottom">
      <div :class="{ low: leftLow }"></div>
      <div :class="{ low: rightLow }"></div>
      <div></div>
      <div></div>
    </div>
  </div>
</template>

<script>
import { Format } from "@/utils/fn";
import { sgsPeList } from "@/utils/filedList";

export default {
  name: "OutItem",
  props: ["data", "bottom", "className", "ET"],
  data() {
    return {
      sgsPeList,
    };
  },
  methods: {
    getTygData(data) {
      return (data.find((item) => item.tyg == 5) || {}).sc || [0, 0];
    },
  },
  computed: {
    getOvertime() {
      if (this.data.sgs) {
        return this.data.sgs.filter((item) => {
          return (
            (item.pe == 1006 && item.tyg == 5) ||
            (item.pe == 1013 && item.tyg == 5)
          );
        });
      }
      return [];
    },
    centerFn() {
      switch (this.data.st) {
        case 0:
          return `<span class="${this.leftLow ? "low" : ""}">${
            this.scoreData[0]
          }</span> - <span class="${this.rightLow ? "low" : ""}">${
            this.scoreData[1]
          }</span>`;
        case 5:
          return `<span class="${this.leftLow ? "low" : ""}">${
            this.scoreData[0]
          }</span> - <span class="${this.rightLow ? "low" : ""}">${
            this.scoreData[1]
          }</span>`;
      }
      return Format(new Date(this.data.btm), "MM/dd");
    },
    leftLow() {
      if (this.data.st == 4 || this.data.st === undefined) {
        return false;
      }
      return this.scoreData[0] < this.scoreData[1];
    },
    rightLow() {
      if (this.data.st == 4 || this.data.st === undefined) {
        return false;
      }
      return this.scoreData[0] > this.scoreData[1];
    },
    scoreData() {
      let scoreData = (
        (this.data.sgs || []).find((item) => item.tyg == 5) || {}
      ).sc || [0, 0];
      return (
        this.data.sgs?.reduce(
          (prev, item) => {
            if ((item.pe == 1006 || item.pe == 1013) && item.tyg == 5) {
              prev = prev.length > 0 ? prev : [0, 0];
              prev[0] = prev[0] + item.sc[0];
              prev[1] = prev[1] + item.sc[1];
            }
            return prev;
          },
          [...scoreData]
        ) || scoreData
      );
    },
  },
};
</script>
<style lang="scss">
.outItem {
  .low {
    opacity: 0.5;
  }
}
</style>
<style lang="scss" scoped>
.outItem {
  &.n2 {
    .content {
      height: 67px;
    }
  }
  .content {
    // display: flex;
    width: 100%;
    justify-content: space-between;
    text-align: center;
    position: relative;
    height: 57px;
    line-height: 30px;
    img {
      vertical-align: top;
    }

    .item {
      //   display: flex;
      //   flex-direction: column;
      //   align-items: center;
      overflow: hidden;
      position: absolute;
      top: 0;
      max-width: 65%;
      line-height: initial;
      &:first-child {
        left: 0;
        transform: translate(-50%, 0);
      }
      &:last-child {
        // float: right;
        right: 0;
        transform: translate(50%, 0);
      }
      /*.hasTms {
        width: 18px;
      }*/
      .name {
        margin: 4px 0;
        text-align: center;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        flex: 1;
      }
    }
    .center {
      //   display: flex;
      align-items: center;
      padding: 0 3px;
      line-height: initial;
      text-align: center;
      .scoreRow {
        font-family: "DIN Medium";
        &.n4Score {
          color: #8d9198;
          font-size: 13px;
          font-weight: 700;
        }
        &.n2Score {
          color: #8d9198;
          font-size: 14px;
          font-weight: 700;
        }
      }
      .etRow {
        color: #8d9198;
        font-size: 12px;
      }
    }
  }
  .unknow {
    img {
      width: 24px;
      height: 24px;
    }
  }
  .line {
    display: flex;
    flex-wrap: wrap;
    // margin: 0 9px;
    div {
      width: 50%;
      height: 6px;
      &:nth-child(1) {
        border-left: 2px solid $out-line;
        border-bottom: 2px solid $out-line;
      }
      &:nth-child(2) {
        border-right: 2px solid $out-line;
        border-bottom: 2px solid $out-line;
      }
      &:nth-child(3) {
        border-right: 2px solid $out-line;
      }
    }
  }
  .bottomLine {
    display: flex;
    flex-wrap: wrap;
    // margin: 0 10px;
    margin-bottom: 6px;
    div {
      width: 50%;
      height: 6px;
      &:nth-child(3) {
        border-left: 2px solid $out-line;
        border-top: 2px solid $out-line;
      }
      &:nth-child(4) {
        border-right: 2px solid $out-line;
        border-top: 2px solid $out-line;
      }
      &:nth-child(1) {
        border-right: 2px solid $out-line;
      }
    }
  }
}
.n8 {
  .etRow {
    display: flex;
    flex-direction: column;
  }
  .bottomLine,
  .line {
    width: 150px;
  }
}
.n4 {
  .bottomLine,
  .line {
    width: 217px;
  }
  .item {
    img {
      width: 32px;
      height: 32px;
    }
  }
}
.n2 {
  .bottomLine,
  .line {
    width: 442px;
  }
  .item {
    img {
      width: 38px;
      height: 38px;
    }
  }
}
.n1 {
  .bottomLine,
  .line {
    width: 250px;
  }
}
</style>
