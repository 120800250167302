<template>
  <div class="Integral container">
    <LeftMenus :menus="menus" :menusHandle="menusHandle" :active="active" />
    <Group :groupData="groupData" v-if="active == 1" />
    <Out v-else :outData="outData" />
  </div>
</template>

<script>
import LeftMenus from "@/components/LeftMenus";
import { getTeamStatList } from "@/service/Integral";
import { getMatchList } from "@/service/Schedule";
import Group from "./components/Group";
import Out from "./components/Out";
import { defaultGroupText, groupTypeList } from "@/utils/filedList";
import { mapState } from 'vuex'
export default {
  name: "Integral",
  components: {
    LeftMenus,
    Group,
    Out,
  },
  data() {
    return {
      menus: [
        { groupValue: 1, groupText: groupTypeList[7][this.getLocal()] },
        { groupValue: 2, groupText: groupTypeList[8][this.getLocal()] },
      ],
      active: 1,
      groupData: [],
      outData: { n8: [], n4: [], n2: [], n3: {}, n1: {} },
      playerData: [],
      fetchTimer: null,
    };
  },
  created() {
    this.getTeamStatList();
    this.getMatchList();
  },
  computed: {
    languageType(){
      return this.common.languageType
    },
    ...mapState(['common'])
  },
  destroyed() {
    clearTimeout(this.fetchTimer);
    this.fetchTimer = null;
  },
  methods: {
    menusHandle(val) {
      this.active = val;
    },
    buildData(data) {
      let groupDataTemp = {};
      data.forEach((item) => {
        if (item.ph == 7) {
          groupDataTemp[item.sph] = groupDataTemp[item.sph] || [];
          groupDataTemp[item.sph].push(item);
        }
      });
      this.groupData = Object.keys(groupDataTemp)
        .map((key) => groupDataTemp[key])
        .sort((curr, next) => curr[0].sph - next[0].sph);
      const thirdGroup = this.groupData.map(item => {
        if(item.length && item[2]){
          return {...item[2], thirdGroup:true}
        }
      }).sort((item, next) => {
        return item.tpr - next.tpr
      })
      if(thirdGroup.length){
        this.groupData = [...this.groupData, thirdGroup]
      }
    },
    getTeamStatList() {
      getTeamStatList({ languageType: this.languageType }).then((res) => {
        if (res.success) {
          this.buildData(res.data);
        }
      });
    },
    getMatchList() {
      getMatchList({ languageType: this.languageType }).then((res) => {
        if (res.success) {
          this.buildOutData(res.data);
          this.fetchTimer = setTimeout(() => {
            this.getMatchList();
          }, 3000);
        }
      });
    },
    buildOutData(data) {
      // 獲取所有淘汰賽列表
      const dataFilter = data.filter((item) => item.ph == 8).sort((curr, next) => curr.or - next.or);
      let outData = {
        n8: [],
        n4: [],
        n2: [],
        n3: {},
        n1: {},
      };
      dataFilter.forEach((item) => {
        if (item.ph != 8) return;

        switch (item.sph) {
          case 9:
            outData.n8.push(item);
            break;
          case 10:
            outData.n4.push(item);
            break;
          case 11:
            outData.n2.push(item);
            break;
          case 12:
            outData.n3 = item;
            break;
          case 13:
            outData.n1 = item;
            break;
        }
      });

      outData.n8.forEach((currItem, index) => {
        if (!currItem.tms || currItem.tms.length == 0) {
          currItem.tms = [
            { na: defaultGroupText.n8[index][0] },
            { na: defaultGroupText.n8[index][1] },
          ];
        }
      });
      this.outData = outData;
    },
  },
};
</script>

<style lang="scss" scoped>
.Integral {
  display: flex;
  height: 100%;
}
</style>
